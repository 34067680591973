<template>
  <div>
    <v-container class="fill-height fullscreen-bg" fluid> 
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12 pa-4 text-center">
            <v-card-title class="headline d-block" style="">{{$t('shared.loading-text')}}</v-card-title>
            <v-card-text class="">
              <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { getAuth, signOut } from "firebase/auth";
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  data: () => ({
    isProcessing: true,
  }),

  async mounted() {
    var view = this.$route.query.view;
    var lang = this.$route.query.lang || 'en';

    const auth = getAuth();

    EventBus.$on('login-state-change', async user => {
      await this.getLoginStatus();
    });
    await this.getLoginStatus();

    await new Promise(r => setTimeout(r, 2000));

    //redirect to home regardless
    this.$router.replace({ name: "home", query: { appcmd: 'close', view: this.$route.query.view, error: 'timeout' } });
  },

  methods: {

    async getLoginStatus() {
      if (this.user) {
        this.signOut();
      }
    },

    async signOut() {
      const auth = getAuth();
      await signOut(auth)
      this.$store.dispatch('setUser', null);
      this.$router.replace({ name: "home", query: { appcmd: 'close', view: this.$route.query.view } });
    },    

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss" scoped>
  .fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-image: url(https://sodisp.imgix.net/hero-cycling-1.jpg?w=2000&h=2000&fit=crop);
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}
</style>

